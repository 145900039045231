<template>
  <div>
    <div class="form sctp-bg-white pad15">
      <el-form :model="form" status-icon :rules="rules" ref="rules"
               class="demo-ruleForm" size="mini">
        <el-row v-for="(experiences, index) in form.experiences" :key="index" class="sctp-flex">
          <el-form-item>
            {{ index + 1 }}、
          </el-form-item>
          <el-form-item :prop="'experiences.' + index + '.awardname'" :rules="rules.awardname">
            <el-input v-model="experiences.awardname" placeholder="获奖名称"></el-input>
          </el-form-item>
          <el-form-item :prop="'experiences.' + index + '.awardtime'" :rules="rules.awardtime">
            <el-date-picker
              v-model="experiences.awardtime"
              type="date"
              placeholder="获奖日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item :prop="'experiences.' + index + '.remarks'">
            <el-input style="width: 350px"
                      type="textarea"
                      :rows="1"
                      resize="none"
                      placeholder="请输入获奖备注"
                      v-model="experiences.remarks">
            </el-input>
          </el-form-item>
          <el-form-item :prop="'experiences.' + index + '.imgurl'" :rules="rules.imgurl">
            <el-upload
              :http-request="fileUploadFunction"
              :data="{...uploadEnum.JOB}"
              action=""
              :show-file-list="false"
              :on-error="uploadFileError"
              :before-upload="beforeUpload"
              :on-success="uploadSuccessPositive.bind(null,{index:index})">
              <template v-if="experiences.imgurl">
                <div @click.prevent="()=>{}">
                  <el-image
                    :src="experiences.imgurl"
                    fit="cover"
                    style="width: 84px; height: 119px"
                  ></el-image>
                </div>
              </template>
              <el-button v-else size="small" type="primary">上传图片</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" class="reduce-item" circle
                       @click="deleteLine(index)"></el-button>
          </el-form-item>
        </el-row>
        <el-row class="sctp-flex pad15">
          <el-form-item>
            <el-button type="primary" @click="submitForm()">保存</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="form.experiences.push({ userid: null, awardname: null, imgurl: null })">
              <i
                class="el-icon-plus"></i>增加
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import uploadEnum from "@/resources/js/uploadenum";

const config = require('../../../resources/js/config')
export default {
  name: 'awardsExperience',
  data() {
    return {
      uploadEnum,
      dialogVisible: false,
      form: {
        experiences: [{
          userid: null,
          awardname: null,
          imgurl: null,
          awardtime: null,
          remarks: null
        }]
      },
      rules: {
        awardname: [{required: true, message: '请输入获奖名称'},
          {
            validator: (rule, value, callback) => {
              const va = /^[\u4E00-\u9FA5A-Za-z_]+$/;
              if (!va.test(value)) {
                callback('专业不能包含特殊符号');
              } else {
                callback();
              }
            },
            trigger: ['change', 'blur']
          }],
        imgurl: [{required: true, message: '请选择获奖图片'}],
        awardtime: [{required: true, message: '请选择获奖时间'}],
      }
    }
  },
  methods: {
    async submitForm() {
      if (await this.$refs.rules.validate()) {
        this.form.experiences.forEach(experience => experience.userid = this.user.userId)
        this.$request.post({
          reqcode: '1125',
          reqdesc: ' 添加或者更新获奖经历',
          data: this.form.experiences
        }).then(res => {
          this.$message.success('保存成功')
          this.queryList()
        })
      } else {
        this.$message.warning('表单填写错误')
      }
    },
    uploadFileError() {
      this.$message.error('文件上传失败');
    },
    beforeUpload(file) {
      const isImage = file.type.startsWith("image");
      const isLtSize = file.size / 1024 / 1024 < 1;
      if (!isImage) {
        this.$message.error('只能上传图片格式!');
      }
      if (!isLtSize) {
        this.$message.error('图片大小不能超过1MB!');
      }
      return isImage && isLtSize && this.$message('文件正在上传') && true;
    },
    deleteLine(index) {
      const id = this.form.experiences[index].id
      if (id != null) {
        this.$confirm('确定要删除嘛?', '提示', {})
        .then(() => {
          this.$request.post({
            reqcode: '1123',
            reqdesc: '删除个人履历信息',
            id: id,
            type: 3
          }).then(res => {
            this.$message.success(res.retDesc)
            this.form.experiences.splice(index, 1)
          })
        })
      } else {
        this.form.experiences.splice(index, 1)
      }
    },
    queryList() {
      this.$request.post({
        reqcode: '1124',
        reqdesc: '查询获奖经历',
        userid: this.user.userId
      }).then(res => {
        const {data} = res
        data.forEach(item => {
          item.fileList = item.imgurl ? item.imgurl.split(',').map(item => {
            return {
              url:  item
            }
          }) : []
        })
        if (data.length > 0) {
          this.form.experiences = data
        }
      })
    },
    uploadSuccessPositive({index}, res, file, fileList) {
      const {src, preview} = res
      this.form.experiences[index].imgurl = src
      this.form.experiences[index].preview = preview
      this.$message.success('上传成功')
    }
  },
  mounted() {
    this.queryList()
  }
}
</script>

<style scoped lang="scss">
.el-form-item {
  margin: 0 5px;
}

.add-item, .reduce-item {
  background: url(/images/user/experience/add-reduce.jpg);
  background-repeat: no-repeat;
  background-size: 63px;
  border: 0;
  padding: 0;
  width: 30px;
  height: 38px;
}

.reduce-item {
  background-position: -31px 0;
}

/deep/ .el-upload--picture-card {
  border-radius: 0px;
  width: 105px;
  height: 148px;
  line-height: 160px;
  margin-bottom: 10px;
}
</style>
